import { useGetPillowWall } from "services-hooks/hooks";
import Image from "next/image";
import { splitToChunks } from "app/utils/array";
import styles from "./wall.module.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Loader from "../icons/Loader";
import { useAppDispatch } from "app/store/store";
import clsx from "clsx";
import { getPillowRadio } from "services-hooks/services";
import { playerActions } from "app/store/slices/player";
import { useTranslate } from "app/hooks/lang";
import { usePlayerContext } from "app/context/player.context";
import { WallItem } from "services-hooks/types";

const WallItemImageSize = 115;

interface WallProps {}
export const Wall: React.FC<WallProps> = () => {
  const { data: wallData, isLoading } = useGetPillowWall();
  const dispatch = useAppDispatch();

  const [imageSize, setImageSize] = useState(WallItemImageSize);
  const [screenWidth, setScreenWidth] = useState(
    typeof window !== "undefined" ? window?.innerWidth - 16 : 1920
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window?.innerWidth - 16 || 1920);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const wallItems = useMemo(() => {
    const items =
      wallData?.data?.body?.content?.slice(
        0,
        Math.floor(screenWidth / WallItemImageSize) * 4
      ) || [];
    setImageSize(screenWidth / (items?.length / 4));
    return items?.filter((wallItem) => !!wallItem.target) || [];
  }, [wallData, screenWidth]);

  const wallItemsRef = useRef<HTMLDivElement>(null);
  const rows = splitToChunks(wallItems, 4);
  const t = useTranslate();
  const { setPlayerEnabled } = usePlayerContext();

  const playWallTrack = async (wallItem: WallItem) => {
    try {
      const radio = await getPillowRadio(wallItem.target!);
      if (!radio.data.body?.content) {
        throw new Error("Category missing");
      }
      setPlayerEnabled(true);
      dispatch(
        playerActions.selectTrackWithQueue({
          track: {
            category: radio.data.body.content,
            podcast: null,
          },
          queue: {
            items: wallItems.map((item) => {
              return {
                permalink: item.target,
                logo: item.picture,
                type: "radio",
                name: radio?.data?.body?.content?.name,
              };
            }),
            activeIndex: wallItems.indexOf(wallItem),
          },
        })
      );
    } catch (e) {
      //TODO: SHOW TOAST
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2>{t("music-on-air")}</h2>
      </div>
      <div
        className={styles.wall_wrapper}
        ref={wallItemsRef}
        data-navigate-grid
      >
        {isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          rows.map((row, index) => {
            return (
              <div
                data-navigate-row
                key={`wall-row-${index}`}
                className={styles.wall_row}
              >
                {row.map((wallItem, wallItemIndex) => {
                  return (
                    <div
                      key={`wall-item-${index}-${wallItemIndex}`}
                      onClick={() => playWallTrack(wallItem)}
                      className={clsx(styles.wall_item)}
                      tabIndex={0}
                      data-navigate-item
                    >
                      <Image
                        className={styles.wall_cover}
                        width={imageSize}
                        height={imageSize}
                        src={wallItem.picture!}
                        layout="fixed"
                        objectFit="cover"
                        unoptimized
                      />
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
