import type { NextPage } from "next";
import Head from "next/head";
import styles from "./index.module.scss";
import { StationsList } from "app/components/stations-list/stations-list.component";
import { useGetPillowCategoriesPath } from "services-hooks/hooks";
import { Category } from "services-hooks/types";
import React from "react";
import { Wall } from "app/components/wall/wall.component";
import { PodcastList } from "app/components/podcats-list/podcast-list.component";
import { useGoBackHistory } from "app/hooks/go-back.hook";
import { useTranslate } from "app/hooks/lang";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";

const Home: NextPage = () => {
  const t = useTranslate();
  const router = useRouter();
  const {
    data,
    error,
    isLoading: stationsLoading,
    fetchNextPage: fetchNextPageStations,
  } = useGetPillowCategoriesPath("radioline_selection", {
    pageSize: 10,
  });

  const {
    data: podcastData,
    error: podcastError,
    isLoading: podcastsLoading,
    fetchNextPage: fetchNextPagePodcasts,
  } = useGetPillowCategoriesPath("podcasts_selection", { pageSize: 10 });

  const radioSelectionList =
    data?.pages.reduce((acc, current) => {
      if (current.data.body?.content) {
        acc = [...acc, ...current.data.body.content];
      }
      return acc;
    }, [] as Category[]) || [];

  const podcastSelectionList =
    podcastData?.pages.reduce((acc, current) => {
      if (current.data.body?.content) {
        acc = [...acc, ...current.data.body.content];
      }
      return acc;
    }, [] as Category[]) || [];
  
    useGoBackHistory("INDEX");
  
  return (
    <div className={styles.container}>
      <Head>
        <title>Radioline</title>
      </Head>
      <NextSeo
        title={t("seo_title_home")}
        titleTemplate={`%s`}
        description={t("seo_description_home")}
        languageAlternates={[
          ...(router.locales || [])
            .filter((locale) => locale !== "defaultLang")
            .map((locale) => ({
              hrefLang: locale,
              href: `https://www.radioline.co/${locale}`,
            })),
          { href: `https://www.radioline.co/en`, hrefLang: "x-default" },
        ]}
        canonical={
          router.locale !== "defaultLang"
            ? `https://www.radioline.co/${router.locale}`
            : `https://www.radioline.co/en`
        }
        openGraph={{
          images: [
            {
              url: "https://www.radioline.co/open-graph.png",
              alt: "Radioline",
            },
          ],
        }}
      />
      <StationsList
        label={t("tabs.stations")}
        items={radioSelectionList}
        loading={stationsLoading}
        className={styles.home_stations_wrapper}
        fetchNextPage={fetchNextPageStations}
      />
      <PodcastList
        label={t("tabs.podcasts")}
        items={podcastSelectionList}
        loading={podcastsLoading}
        className={styles.home_podcasts_wrapper}
        fetchNextPage={fetchNextPagePodcasts}
      />
      <Wall />
    </div>
  );
};

export default Home;
